import {
  createTheme,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import axios from "axios";
import DropDown from "../components/DropDown";
import Footer from "../components/Footer";
import SideDrawer from "../components/SideDrawer";
import NavBar from "../components/NavBar";

const globalTheme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#0c0a0b",
      main: "#0c0a0b",
      dark: "#0c0a0b",
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: [
      "Google Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function removeLoader() {
  setTimeout(function () {
    document.getElementById("loader")?.classList.add("fade-out");
  }, 2000);
  setTimeout(function () {
    document.getElementById("loader")?.remove();
  }, 2500);
}

export default function ContactUs() {
  removeLoader();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [sendContact, setSendContact] = React.useState(false);
  const [sendButton, setSendButton] = React.useState("Submit");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleProductsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenProducts((previousOpen) => !previousOpen);
  };

  const sendContactRequest = () => {
    setSendContact(true);
    axios
      .post(
        "https://us-central1-habiot.cloudfunctions.net/synthetic-textiles-data-ingest",
        { action: "POST_MESSAGE", name: name, email: email, message: message }
      )
      .then((response) => {
        if (response.status === 200) {
          setSendButton("Success!");
        }
        setSendContact(false);
      });
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <SideDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <NavBar
        handleProductsClick={handleProductsClick}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
      <Grid container sx={{ minHeight: "80vh" }}>
        {/* CONTACT US SECTION */}
        <Grid
          item
          md={12}
          lg={6}
          sx={{ alignSelf: "center", px: { xs: 4, md: 6, lg: 8 }, py: 4 }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                id="contact-us"
                variant="h4"
                fontWeight="bold"
                sx={{ py: 4 }}
              >
                Contact us
              </Typography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <Typography fontSize="1.2rem">
                Thank you for your interest in Synthetic Textiles. Please see
                below for the best ways to contact our team.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} py={2} sx={{ pr: { md: 2 } }}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                onChange={(event) => setName(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} md={6} py={2} sx={{ pl: { md: 2 } }}>
              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                onChange={(event) => setEmail(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} py={2}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                onChange={(event) => setMessage(event.target.value as string)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} py={2}>
              <LoadingButton
                loading={sendContact}
                loadingIndicator="Sending..."
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  if (name === "") {
                    window.alert("Name is required!");
                  } else if (email === "") {
                    window.alert("Email is required!");
                  } else if (email === "") {
                    window.alert("Email is required!");
                  } else if (sendButton === "Submit") {
                    sendContactRequest();
                  }
                }}
              >
                {sendButton}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            textAlign: "center",
            py: 4,
            alignSelf: "center",
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            alt="contact-us"
            style={{ width: "-webkit-fill-available" }}
            src="/images/contact/contact-us.jpeg"
          />
        </Grid>
      </Grid>
      <Grid>
        <Footer />
      </Grid>
      <DropDown
        open={openProducts}
        anchorEl={anchorEl}
        setOpenProducts={setOpenProducts}
        setOpenDrawer={setOpenDrawer}
      />
    </ThemeProvider>
  );
}
